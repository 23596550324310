/**
 * --------------------------------------------------------------------------
 * Ace (v2.1.0): toaster.js
   Wrapper for Bootstrap's toast elements
*/

import $ from 'jquery'
import bootstrap from 'bootstrap'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = 'aceToaster'
const VERSION = '2.1.0'
const DATA_KEY = 'ace.toaster'
const EVENT_KEY = `.${DATA_KEY}`

const Event = {
  CLEAR: `clear${EVENT_KEY}`,
  ADD: `add${EVENT_KEY}`,
  ADDED: `added${EVENT_KEY}`
}

const DefaultType = {
  placement: 'string',
  close: 'boolean',
  autoremove: 'boolean',
  delay: 'number',
  template: 'string',
  alert: 'boolean'
}

const Default = {
  placement: 'tr',
  close: true,
  autoremove: true,
  delay: 3000,
  template: '<div class="toast"><div class="d-flex ml-4px px-3 py-25 bgc-white"><div class="toast-image d-flex align-items-center m-0 p-0 align-self-auto"></div><div class="toast-main"><div class="toast-header"></div><div class="toast-body"></div></div></div></div>',
  alert: true
}

const toaster_gritter_class = {
    'gritter-success': {
        'className': 'bgc-success-m3 border-0 opacity-1 text-100 text-dark',
        'headerClass': 'bgc-transparent border-0 p-0 text-600 text-120 text-dark',
        'bodyClass': 'p-0 text-110',
        'titleClass': 'mr-45',
        'template': '<div class="toast"><div class="animation h-100 bgc-success pos-abs"></div><div class="d-flex ml-4px px-3 py-25 bgc-success-l1"><div class="toast-image d-flex align-items-center m-0 p-0 align-self-auto"></div><div class="toast-main p-0"><div class="toast-header"><button type="button" data-dismiss="toast" aria-label="Close" class="close close-alt"><i class="fal fa-times" aria-hidden="true"></i></button></div><div class="toast-body"></div></div></div></div>',
        'icon': '<i class="fas fa-check-circle fa-2x mr-3 text-success"></i>',
        'alert': false
    },
    'gritter-info': {
        'className': 'bgc-info-m3 border-0 opacity-1 text-100 text-dark',
        'headerClass': 'bgc-transparent border-0 p-0 text-600 text-120 text-dark',
        'bodyClass': 'p-0 text-110',
        'titleClass': 'mr-45',
        'template': '<div class="toast"><div class="animation h-100 bgc-info pos-abs"></div><div class="d-flex ml-4px px-3 py-25 bgc-info-l1"><div class="toast-image d-flex align-items-center m-0 p-0 align-self-auto"></div><div class="toast-main p-0"><div class="toast-header"><button type="button" data-dismiss="toast" aria-label="Close" class="close close-alt"><i class="fal fa-times" aria-hidden="true"></i></button></div><div class="toast-body"></div></div></div></div>',
        'icon': '<i class="fas fa-info-circle fa-2x mr-3 text-info"></i>',
        'alert': false
    },
    'gritter-error': {
        'className': 'bgc-danger-m3 border-0 opacity-1 text-100 text-dark',
        'headerClass': 'bgc-transparent border-0 p-0 text-600 text-120 text-dark',
        'bodyClass': 'p-0 text-110',
        'titleClass': 'mr-45',
        'template': '<div class="toast"><div class="animation h-100 bgc-danger pos-abs"></div><div class="d-flex ml-4px px-3 py-25 bgc-danger-l1"><div class="toast-image d-flex align-items-center m-0 p-0 align-self-auto"></div><div class="toast-main p-0"><div class="toast-header"><button type="button" data-dismiss="toast" aria-label="Close" class="close close-alt"><i class="fal fa-times" aria-hidden="true"></i></button></div><div class="toast-body"></div></div></div></div>',
        'icon': '<i class="fas fa-times-circle fa-2x mr-3 text-danger"></i>'
    },
    'gritter-warning': {
        'className': 'bgc-warning-m3 border-0 opacity-1 text-100 text-dark',
        'headerClass': 'bgc-transparent border-0 p-0 text-600 text-120 text-dark',
        'bodyClass': 'p-0 text-110',
        'titleClass': 'mr-45',
        'template': '<div class="toast"><div class="animation h-100 bgc-warning pos-abs"></div><div class="d-flex ml-4px px-3 py-25 bgc-warning-l1"><div class="toast-image d-flex align-items-center m-0 p-0 align-self-auto"></div><div class="toast-main p-0"><div class="toast-header"><button type="button" data-dismiss="toast" aria-label="Close" class="close close-alt"><i class="fal fa-times" aria-hidden="true"></i></button></div><div class="toast-body"></div></div></div></div>',
        'icon': '<i class="fas fa-exclamation-circle fa-2x mr-3 text-warning"></i>'
    },
    'gritter-light': {
        'className': 'bgc-dark-m3 border-0 opacity-1 text-100 text-dark',
        'headerClass': 'bgc-transparent border-0 p-0 text-600 text-120 text-dark',
        'bodyClass': 'p-0 text-110',
        'titleClass': 'mr-45',
        'template': '<div class="toast"><div class="animation h-100 bgc-dark pos-abs"></div><div class="d-flex ml-4px px-3 py-25 bgc-white"><div class="toast-image d-flex align-items-center m-0 p-0 align-self-auto"></div><div class="toast-main p-0"><div class="toast-header"><button type="button" data-dismiss="toast" aria-label="Close" class="close close-alt"><i class="fal fa-times" aria-hidden="true"></i></button></div><div class="toast-body"></div></div></div></div>',
        'alert': false
    },
    'gritter-inverse': {
        'className': 'bgc-inverse-m1 border-0 opacity-1 text-100 text-white',
        'headerClass': 'bgc-transparent border-0 p-0 text-600 text-120 text-white',
        'bodyClass': 'p-0 text-110',
        'titleClass': 'mr-45',
        'template': '<div class="toast"><div class="animation h-100 bgc-inverse-d2 pos-abs"></div><div class="d-flex ml-4px px-3 py-25 bgc-inverse"><div class="toast-image d-flex align-items-center m-0 p-0 align-self-auto"></div><div class="toast-main p-0"><div class="toast-header"><button type="button" data-dismiss="toast" aria-label="Close" class="close close-alt"><i class="fal fa-times" aria-hidden="true"></i></button></div><div class="toast-body"></div></div></div></div>',
        'alert': false
    }
};

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Toaster {
  constructor () {
    this._lastToastId = 0
    this._toast = null
  }

  static get VERSION () {
    return VERSION
  }

  static get DefaultType () {
    return DefaultType
  }

  static get Default () {
    return Default
  }

  // Public methods
  add (config) {
    if(typeof config['text'] !== 'undefined'){
        config['body'] = config['text'];
    }
    if(typeof config['time'] !== 'undefined'){
        config['delay'] = config['time'];
    }
    if(typeof config['position'] !== 'undefined'){
        var toaster_possition = config['position'];
        toaster_possition = toaster_possition.replace("top-left", "tl");
        toaster_possition = toaster_possition.replace("top-left", "tr");
        toaster_possition = toaster_possition.replace("top-center", "tc");
        toaster_possition = toaster_possition.replace("bottom-left", "bl");
        toaster_possition = toaster_possition.replace("bottom-right", "br");
        toaster_possition = toaster_possition.replace("bottom-center", "bc");
        config['placement'] = toaster_possition;
    }
    if(typeof config['class_name'] !== 'undefined'){
        var class_name = config['class_name'];
        var gritter_class = class_name.split(' ');
        for(var it in gritter_class){
            if(typeof toaster_gritter_class[gritter_class[it]] !== 'undefined'){
                config['className'] = toaster_gritter_class[gritter_class[it]]['className'];
                config['headerClass'] = toaster_gritter_class[gritter_class[it]]['headerClass'];
                config['bodyClass'] = toaster_gritter_class[gritter_class[it]]['bodyClass'];
                config['template'] = toaster_gritter_class[gritter_class[it]]['template'];
                config['titleClass'] = toaster_gritter_class[gritter_class[it]]['titleClass'];
                if(typeof toaster_gritter_class[gritter_class[it]]['icon'] !== 'undefined')
                    config['icon'] = toaster_gritter_class[gritter_class[it]]['icon'];
                if(typeof toaster_gritter_class[gritter_class[it]]['alert'] !== 'undefined')
                    config['alert'] = toaster_gritter_class[gritter_class[it]]['alert'];
            }
        }
    }

    const _config = this._getConfig(config)

    const $newToast = $(_config.template)
    this._toast = $newToast[0]

    this._lastToastId++
    $newToast.addClass('ace-toaster-item').attr({ id: `ace-toaster-item-${this._lastToastId}`, 'aria-atomic': 'true' })
    if (_config.alert) {
      $newToast.attr({ role: 'alert', 'aria-live': 'assertive' })
    } else {
      $newToast.attr({ role: 'status', 'aria-live': 'polite' })
    }

    const $toastHeader = $newToast.find('.toast-header')
    if (_config.title) {
      let title = typeof _config.title === 'function' ? _config.title.call(this._toast, _config) : _config.title
      title = $(`<div class="toast-title">${title}</div>`)

      if (_config.titleClass) {
        title.addClass(_config.titleClass)
      }
      $toastHeader.append(title)
    }

    if (jQuery(["after_open", "after_close"]).each(function(t, n) {
        $[NAME]["_" + n + "_" + this._lastToastId] = jQuery.isFunction(config[n]) ? config[n] : function() {}
        }),_config.close) {
        let close = $newToast.find('[data-dismiss="toast"]')
        if (close.length === 0) {
            close = $('<button type="button" data-dismiss="toast" aria-label="Close"><span aria-hidden="true">&times;</span></button>')
            $toastHeader.append(close)
        }
        close.addClass(_config.closeClass ? _config.closeClass : 'close')
    }

    if (_config.body) {
      $newToast.find('.toast-body').append(typeof _config.body === 'function' ? _config.body.call(this._toast, _config) : _config.body)
      if (_config.bodyClass) {
        $newToast.find('.toast-body').addClass(_config.bodyClass)
      }
    }

    if (_config.image) {
      $newToast.find('.toast-image').append(`<img src="${_config.image}" />`)
    }
    if (_config.icon) {
      $newToast.find('.toast-image').append(_config.icon)
    }
    if (!(_config.image || _config.icon)) $newToast.find('.toast-image').remove()

    if (_config.className) {
      $newToast.addClass(_config.className)
    }
    if (_config.headerClass) {
      $toastHeader.addClass(_config.headerClass)
    }

    return this._addToContainer($newToast, _config)

    //return $newToast.get(0)
  }

  // add an existing toast element to our container
  addEl (element, config) {
    const _config = this._getConfig(config)

    this._toast = element
    const $existingToast = $(this._toast).addClass('ace-toaster-item')
    if (!$existingToast.attr('id')) $existingToast.attr('id', `ace-toaster-item-${++this._lastToastId}`)

    this._addToContainer($existingToast, _config, false)
  }

  // add toast element to container
  _addToContainer ($toast, _config, isNewElement = true) {
    // trigger ADD event before adding it to our container
    const addEvent = new $.Event(Event.ADD)

    var _toast = $toast.get(0)

    addEvent.target = _toast
    $(document).trigger(addEvent)
    if (addEvent.isDefaultPrevented()) {
      if (isNewElement) $toast.remove()
      return null
    }
    // end of trigger

    // add the toaster container to body
    let container = $(`.ace-toaster-container.position-${_config.placement}`).eq(0)
    if (container.length === 0) {
      container = $(`<div class="ace-toaster-container position-${_config.placement}"/>`).appendTo(document.body)
    }
    if (_config.belowNav) {
      container.addClass('toaster-below-nav')
    }

    // add to container
    if (_config.placement.indexOf('b') === 0) { // bottom placement
      container.prepend($toast)
    } else {
      container.append($toast)
    }

    // without having an initial .toast element, fade-in animation isn't taking place??!!
    let dummy = $('#ace-toaster-dummy-toast-1')
    if (dummy.length === 0) dummy = $('<div id="ace-toaster-dummy-toast-1" class="toast d-none invisible"></div>').appendTo('body')
    dummy.toast('show')
    /// ///////////////////////////////////////////////

    const _toastOptions = {}
    if (_config.sticky === true || _config.autohide === false) {
        _toastOptions.autohide = false;
        $toast.find('.animation').css('width', '4px').removeClass('animation');
    }
    if (_config.animation === false) _toastOptions.animation = false // if delay is below 30, we consider it as seconds, not milliseconds
    _toastOptions.delay = _config.delay > 30 ? _config.delay : _config.delay * 1000;
    if (_config.delay != Default.delay) $toast.find('.animation').css('animation-duration', (_toastOptions.delay + 300) + 'ms');

    if (_config.width) $toast.css('width', isNaN(_config.width) ? _config.width : _config.width + 'px')

    $toast
      .toast(_toastOptions)
      .toast('show')
      .one('hidden.bs.toast.1', function () {
        // show it again (invisibly with opacity = 0) and use bootstrap Collapse plugin to hide it, so that other toasts stacked below it move up smoothly
        $toast.removeClass('hide').addClass('show').collapse('hide').one('hidden.bs.collapse', function () {
            var t = jQuery.isFunction(_config.after_close) ? _config.after_close : function() {};
            $toast.toast('dispose')
            if (_config.autoremove) {
                $toast.remove()
            }
            t(this.id,_config)
        })
      }), (jQuery.isFunction(_config.after_open) ? _config.after_open : function() {})("ace-toaster-item-".concat(this._lastToastId), _config);

    // trigger ADDED event before adding it to DOM
    const addedEvent = new $.Event(Event.ADDED)
    addedEvent.target = _toast

    $(document).trigger(addedEvent)

    return _toast;
  }

  // hide toasts
  remove (id) {
    this.hide(id, true)
  }

  removeAll (placement = null) {
    this.hideAll(placement, true)
  }

  // remove toast by ID or element reference
  hide (id, remove = false) {
    const selector = isNaN(id) ? id : '#ace-toaster-item-' + parseInt(id)
    this._hideBySelector(selector, remove)
  }

  // remove ALL toasts
  hideAll (placement = null, remove = false) {
    // trigger CLEAR event before removing ALL
    const clearEvent = new $.Event(Event.CLEAR) // ,  { placement: placement || 'all', remove: remove })
    $(document).trigger(clearEvent, { placement: placement || 'all', remove: remove })
    if (clearEvent.isDefaultPrevented()) {
      return
    }
    // end of trigger

    let selector = '.toast.ace-toaster-item'
    if (placement) selector = `.ace-toaster-container.position-${placement} ${selector}`
    this._hideBySelector(selector, remove)
  }

  _hideBySelector (selector, remove = false) {
    $(selector).each(function () {
      var $toast = $(this)
      if ($toast.is(':visible')) {
        // fade out and then remove
        $toast.toast('hide')
          .off('hidden.bs.toast.1')// remove the previous handler above (because it has autoremove)
          .one('hidden.bs.toast.2', function () {
            $toast.toast('dispose')
            if (remove) $toast.remove()
          })
      } else {
        $toast.toast('dispose')
        // instantly remove if not visible
        if (remove) $toast.remove()
      }
    })
  }

  // Private methods
  _getConfig (config) {
    config = {
      ...Default,
      ...typeof config === 'object' && config ? config : {}
    }

    if (typeof bootstrap !== 'undefined') {
      bootstrap.Util.typeCheckConfig(
        NAME,
        config,
        this.constructor.DefaultType
      )
    }

    return config
  }

  // Static methods
  static _jQueryInterface (config) {
    return this.each(function () {
      config = {
        ...{ autoremove: false }, // don't autoremove it
        ...$(this).data(),
        ...typeof config === 'object' && config ? config : {}
      }

      $.aceToaster.addEl(this, config)
    })
  }
}

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
*/

if (typeof $ !== 'undefined') {
  $[NAME] = new Toaster()
  const JQUERY_NO_CONFLICT = $.fn[NAME]
  $.fn[NAME] = Toaster._jQueryInterface
  $.fn[NAME].Constructor = Toaster
  $.fn[NAME].noConflict = () => {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Toaster._jQueryInterface
  }
}

export default Toaster
